import React, { useEffect, useState } from "react";
import axios from "axios";


export default function News(props) {
  const [Announcement, setAnnouncement] = useState("");




  useEffect(() => {
    if (!props.isLoggedIn) {
      return;
    }
    if (!props.userid) return

    axios
      .post("https://liveapi247.live/api1/news", {
        id: props.userid,
        token: props.utoken,
      })
      .then((result) => {
        if (result.status === 210) {

          setAnnouncement(result.data[0].message);
        }

      })

      .catch((e) => {
        //setIsError(true);
      });

  }, []);


  return (
    <div>
      {props.isLoggedIn === true && (
        <div _ngcontent-njs-c61 class="marquee-box">
          <h4 _ngcontent-njs-c61  >News</h4>
          <div _ngcontent-njs-c61 class="marquee">
            <div _ngcontent-njs-c61 class="js-marquee-wrapper">
              <div _ngcontent-njs-c61
                class="js-marquee"
                style={{ marginRight: "0px", float: "left" }}
              >
                <a _ngcontent-njs-c61  >
                  {" "}
                  {/* <span>2 Jan 2022</span> */}
                  {Announcement}
                </a>
                {/* <a  ><span>15 Sep 2020</span>skyexch IS NOW POWERED BY BETFAIR .</a>*/}{" "}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
